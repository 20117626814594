import AddressSection from './Address'
import CompanyContractSection from './CompanyContract'
import CustomerBillingSection from './CustomerBilling'
import OptionSection from './Option'
import PaymentMethodSection from './PaymentMethod'
import PreferenceSection from './Preference'
import PrimarySection from './Primary'
import SecondarySection from './Secondary'
import SubscriptionSection from './Subscription'

export {
  AddressSection,
  CompanyContractSection,
  CustomerBillingSection,
  OptionSection,
  PaymentMethodSection,
  PreferenceSection,
  PrimarySection,
  SecondarySection,
  SubscriptionSection
}